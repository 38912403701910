/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Availability } from '../models/availability';
import { Center } from '../models/center';
import { CenterDescription } from '../models/center-description';
import { CenterFacilities } from '../models/center-facilities';
import { ResourceTypeForActivity } from '../models/ResourceTypeActivity';
import { ResourcesTypes } from '../models/resourceTypes';
import { Service } from '../models/service';
import { ServiceTypeForActivity } from '../models/ServiceType-activity';
import { ChracteristicTypes } from '../models/serviceTypecharacteristic';
import {Wallets} from "../models/wallets";
import {Subscription} from "../models/subscription";


export interface CentersResponse {
  availabilities: Availabilities;
}

export interface Availabilities {
  centers: Center[];
}

@Injectable({
  providedIn: 'root'
})
export class CentersService {

  private centersUrl: string = environment.bookingApiUrl + '/v1/users/me/centers';
  private employyeUrl: string = environment.bookingApiUrl + '/v1/users/me/employees';
  private memberUrl: string = environment.bookingApiUrl + '/v1/users/me/members';
  private walletUrl: string = environment.bookingApiUrl + '/v1/users/me/wallets';
  private subscriptionUrl: string = environment.bookingApiUrl + '/v1/users/me/subscriptions';
  private centerUrl: string = environment.bookingApiUrl + '/v1/centers';
  private availabilitiesUrl = environment.bookingApiUrl + '/v1/availabilities';
  private activitiesUrl = environment.bookingApiUrl + '/v1/activities';
  private characteristictsUrl = environment.bookingApiUrl + '/v1/characteristictypes';
  private resourcesTypesUrl = environment.bookingApiUrl + '/v1/resourcetypes';
  private centerAvailabilities = environment.bookingApiUrl + '/v2/centers/';
  private ServiceTypeUrlActivity = environment.bookingApiUrl + '/v1/servicetypes?activity';
  private ServiceTypeUrl = environment.bookingApiUrl + '/v1/servicetypes';
  private filterUrl = environment.bookingApiUrl + '/v1/filters';
  constructor(private http: HttpClient) {
    this.http = http;
  }

  listCenters(): Observable<any> {
    return this.http.get<Center[]>(`${this.centersUrl}`)
      .pipe(
        map(res => res.map(center => Center.fromJson(center)))
      );
  }
  listCe(): Observable<any> {
    return this.http.get<any>(`${this.employyeUrl}`)

  }
  getCenter(id: string): Observable<Center> {
    return this.http.get<Center>(`${this.centerUrl}/${id}`)
      .pipe(
        map(res => Center.fromJson(res))
      );
  }

  getCenterByDomain(domain: string): Observable<Center> {
    return this.http.get<Center>(`${this.centerUrl}/domains/${domain}`)
      .pipe(
        map(res => Center.fromJson(res))
      );
  }

  getCenterActivites(id: string): Observable<Center> {
    return this.http.get<Center>(`${this.activitiesUrl}?centerId=${id}`)
      .pipe(
        map(res => Center.fromJson(res))
      );
  }

  getCenterDescriptions(id: string): Observable<CenterDescription[]> {
    return this.http.get<CenterDescription[]>(`${this.centerUrl}/${id}/descriptions`);
  }

  listCenterFacilities(id: string): Observable<CenterFacilities[]> {
    return this.http.get<CenterFacilities[]>(`${this.centerUrl}/${id}/facilities`);
  }

  getCenters(
    point,
    dateTime,
  ): Observable<Availability> {
    // const myFormattedDate = dateTime.format('YYYY-MM-DD')
    const myFormattedDate = new Date().toISOString().slice(0, 10);
    return this.http.get<Availability>(
      `${this.availabilitiesUrl}?sort=allNearby&date=${myFormattedDate}&location=${point.lat},${point.lng}&radius=100000`
    ).pipe(
      map(availability => Availability.fromJson(availability))
    );
  }

  getCentersAvailabilities(
    centerId,
    activity,
    datecc,
    types,
    filters,
    partySize,
    endDate = null,
    night = true
  ): Observable<any> {
    const fitnessSports = ['aquagym',
      'cardio',
      'danse',
      'muscle-strengthening',
      'relaxation-well-being',
      'team-training', 'fitness'];
    let isFitness = false;
    const activities = activity ? activity.split(',') : [];
    activities.forEach(a => {
      if (fitnessSports.findIndex(e => e == a) != -1) {
        isFitness = true;
      }
    });
    // ?activities=tennis%2Cpadel&fromDate=2021&toDate=2021&priceMin=1000&priceMax=3500&duration=60&serviceTypes=rental&characteristics=clay%2Cterre&partySize=2
    let jourd = '';
    let today = new Date().toISOString().slice(0, 10);
    let formattedFilters = '';

    for (let i = 0; i < filters.length; i++) {
      formattedFilters = formattedFilters + filters[i];
      if (i !== (filters.length - 1)) {
        formattedFilters = formattedFilters + ',';
      }
    }
    const serviceTypes = (types === '') ? '' : `&serviceTypes=${types}`;
    const characteristics = (filters === '') ? '' : `&characteristics=${filters}`;
    const party = (partySize === '') ? '' : `&partySize=${partySize}`;
    if (datecc !== undefined) {
      if (endDate !== null) {
        if(night){
          jourd = `date.from=${datecc}&date.to=${endDate.slice(0, 10)}T23:59`;
        } else {
          jourd = `date.from=${datecc}&date.to=${endDate}`;
        }
      } else if ((endDate == '' || endDate == null) && isFitness) {
        let date = new Date();
        date.setDate(date.getDate() + 8);
        jourd = `date.from=${datecc}&date.to=${date.toISOString().slice(0, 10)}T23:59`;
      } else if (datecc.length === 16) {
        jourd = `date.from=${datecc}&date.to=${datecc.slice(0, 10)}T23:59`;
      } else {
        jourd = `date.from=${datecc}T00:00&date.to=${datecc}T23:59`;
      }
    } else {
      if (isFitness) {
        let date = new Date();
        date.setDate(date.getDate() + 8);
        jourd = `date.from=${today}&date.to=${date.toISOString().slice(0, 10)}T23:59`;
      } else {
        jourd = `date.from=${today}T00:00&date.to=${today}T23:59`;
      }
    }
    return this.http.get<any>(`${this.centerAvailabilities}${centerId}/availabilities?${jourd}&activities=${activity}${serviceTypes}${characteristics}${party}`);
  }

  public getCenterBySport(sport: string): Observable<Center[]> {
    return this.http.get<Center[]>(`${this.centerUrl}?activity=${sport}`);
  }

  listCenterCharecteristicTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.characteristictsUrl}`);
  }

  listCenterCharecteristicTypesById(id: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.characteristictsUrl}/${id}`);
  }

  listCenterServiceTypeByActivity(activity: string): Observable<ServiceTypeForActivity> {
    return this.http.get<ServiceTypeForActivity>(`${this.ServiceTypeUrlActivity}=${activity}`);
  }

  listCenterResourcesTypes(): Observable<ResourcesTypes[]> {
    return this.http.get<ResourcesTypes[]>(`${this.resourcesTypesUrl}`);
  }

  listCenterResourcesTypesByType(type: string): Observable<ResourcesTypes> {
    return this.http.get<ResourcesTypes>(`${this.resourcesTypesUrl}/${type}`);
  }

  listCenterServiceTypeCharactiristic(type: string): Observable<ChracteristicTypes> {
    return this.http.get<ChracteristicTypes>(`${this.ServiceTypeUrl}/${type}`);
  }

  listCenterResourcesTypesByActivity(activity: string): Observable<ResourceTypeForActivity> {
    return this.http.get<ResourceTypeForActivity>(`${this.resourcesTypesUrl}/activity/${activity}`);
  }

  getCenterFeedbacks(id: string): Observable<any> {
    return this.http.get<any>(`${this.centerUrl}/${id}/feedbacks`);
  }

  getCenterService(centerId: string): Observable<Service> {
    return this.http.get<Service>(`${this.centerUrl}/${centerId}/services`);
  }

  getFilter(centerId: string, activityId: string): Observable<any> {
    return this.http.get<any>(`${this.filterUrl}?centerId=${centerId}&activityId=${activityId}`);
  }

  getFilterByActivity(activityId: string): Observable<any> {
    return this.http.get<any>(`${this.filterUrl}?activityId=${activityId}`);
  }

  requestDemand(date: string, centerId: string): Observable<void> {
    return this.http.post<any>(`${this.centerUrl}/${centerId}/askforreservation`, { startDate: date })
  }

  getUserCenterMember(): Observable<any> {
    return this.http.get(`${this.memberUrl}`)
  }

  getWallet(): Observable<Wallets> {
    return this.http.get<Wallets>(`${this.walletUrl}`);
  }

  getUserMeSubscriptions(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>(`${this.subscriptionUrl}`);
  }
}
