export const environment = {
  production: true,
  useEmulators: false,
  bookingApiUrl: 'https://api-booking.anybuddyapp.com',
  organiserApiUrl: 'https://api.anybuddyapp.com',
  authorize: 'https://api-global.decathlon.net/connect/oauth/authorize',
  accessToken: 'https://api-global.decathlon.net/connect/oauth/token',
  clientId: '8e9f2573-4f27-1125-75c3-99fad5d34fr2',
  clientSecret:
    'DPy7fnB5UMdob3aUEbwwq5fSCFFV7QRxTN4dvglRAf9yNme7PxkgBn8I5cCvKh0eFYn30UU7KHLVd0Bo3gPFVKdVauZ9Iyp42vFaKnOxQu5LsG5MITk10Rbo2f3WgbBB',
  stripeKey: 'pk_live_mXqXov1nqeDAWo6y2K3kxU1F',
  host: 'https://reservation.forest-hill.fr',
  imageCDN: 'https://res.cloudinary.com/anybuddy/image/upload/',
  gtmId: 'GTM-T29LFBFL',
  installApp: false,
  whitelabelId: '11',
  firebase: {
    dynamicLinkDomain: 'anybuddygo.page.link',
    projectId: 'anybuddy-91142',
    appId: '1:429721281782:web:a3b72a0e338400dc4878d7',
    databaseURL:
      'https://anybuddy-91142-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'anybuddy-91142.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyA3H8XcpOvtiSOVea-jf9QVOKqSRXMy9DQ',
    authDomain: 'anybuddy-91142.firebaseapp.com',
    messagingSenderId: '429721281782',
    measurementId: 'G-LMQ4MK37KJ',
    appCheckKey: '6LcGkqYlAAAAAE6E_fXWTbbyS3ui82-8ZgXov3Xu',
    tenantId: null
  }
};
